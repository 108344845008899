import { responsiveStyles } from "./util"

import "./fonts"

// Place global Typography in this file
export const primaryFont = "'Inter', -apple-system, sans-serif"
export const secondaryFont = "'Gilroy', -apple-system, sans-serif"
export const normal = 400
export const medium = 700
export const bold = 700

export const bodyLarge = `
	${responsiveStyles("font-size", 24, 22, 20, 18)}
	line-height: 1.5em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`
export const bodyMedium = `
	${responsiveStyles("font-size", 20, 18, 17, 16)}
	line-height: 1.5em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`
export const body = `
	${responsiveStyles("font-size", 17, 15, 15, 14)}
	line-height: 1.5em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`

export const bodyNew = `
	${responsiveStyles("font-size", 18, 16, 14, 14)}
	line-height: 1.5em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`
export const bodySmall = `
	${body}
	${responsiveStyles("font-size", 16, 14, 13, 12)}
`

export const bodyTiny = `
	${body}
	${responsiveStyles("font-size", 14, 12, 12, 10)}
`

// Big Headline
export const h1 = `
	${responsiveStyles("font-size", 120, 100, 80, 40)}
	line-height: 1.1em;
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${bold};
	letter-spacing: 0;
	text-transform: none;
`

export const h1New = `
	${responsiveStyles("font-size", 100, 80, 50, 50)}
	line-height: 1.1em;
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${normal};
	letter-spacing: 0;
	text-transform: none;
`

export const h1Light = `
	${responsiveStyles("font-size", 54, 48, 36, 32)}
	line-height: 1.1em;
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${normal};
	letter-spacing: 0;
	text-transform: none;
`

// Medium Headline
export const h2 = `
	${responsiveStyles("font-size", 100, 80, 50, 40)}
	line-height: 1.1em;
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${bold};
	letter-spacing: 0;
	text-transform: none;
`
export const h2New = `
${responsiveStyles("font-size", 80, 60, 40, 30)}
	line-height: 1.1em;
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${normal};
	letter-spacing: 0;
	text-transform: none;
`

// Small Headline
export const h3 = `
	${responsiveStyles("font-size", 66, 48, 36, 30)}
	line-height: 1.1em;
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${bold};
	letter-spacing: 0;
	text-transform: none;
`
export const h3New = `
  ${responsiveStyles("font-size", 60, 40, 30, 30)}
  line-height: 1.1em;
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${normal};
	letter-spacing: 0;
	text-transform: none;
`

// Subheadline in rich text
export const h4 = `
	${responsiveStyles("font-size", 44, 32, 20, 24)}
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${bold};
	line-height: 1.25em;
	letter-spacing: 0;
	text-transform: none;
`

export const h4New = `
	${responsiveStyles("font-size", 44, 32, 20, 24)}
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${normal};
	line-height: 1.25em;
	letter-spacing: 0;
	text-transform: none;
`

// Small Headline Inter
export const h5 = `
	${responsiveStyles("font-size", 36, 28, 20, 24)}
	font-family: ${primaryFont};
	font-weight: ${bold};
	line-height: 1.25em;
	letter-spacing: 0;
	text-transform: none;
`

export const h5New = `
	${responsiveStyles("font-size", 36, 28, 20, 24)}
	font-family: ${primaryFont};
	font-weight: ${normal};
	line-height: 1.25em;
	letter-spacing: 0;
	text-transform: none;
`

export const h6 = `
	${responsiveStyles("font-size", 18, 16, 15, 14)}
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${bold};
	line-height: 1.2em;
`

export const h6New = `
	${responsiveStyles("font-size", 18, 16, 15, 14)}
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${normal};
	line-height: 1.2em;
`

export const articleTitle = `
	${responsiveStyles("font-size", 32, 26, 24, 22)}
	font-family: ${secondaryFont};
	font-variant: no-common-ligatures;
	font-weight: ${bold};
	line-height: 1.25em;
	letter-spacing: 0;
	text-transform: none;
`

export const articleTitleSmall = `
	${articleTitle}
	${responsiveStyles("font-size", 28, 22, 20, 18)}
`

export const blockquote = `
	${bodyLarge}
	font-style: normal;
`

export const eyebrow = `
	${h6}
`

export const smallCaps = `
	${h6}
	${responsiveStyles("font-size", 14, 14, 13, 12)}
`

export const buttonStyle = `
	${body}
	font-weight: ${bold};
	line-height: 1em;
`

export const navStyle = `
	${body}
	font-weight: ${bold};
	${responsiveStyles("font-size", 14, 14, 14, 14)}
	line-height: 1em;
`
