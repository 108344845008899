import { lighten, darken, rgba } from "polished"

export const black = "#000"
export const white = "#fff"

// Site Specific Colors
export const sky = "#64C5FA"
export const purple = "#476FD7"
export const charcoal = "#2D2926"
export const indigo = "#1C4063"
export const indigoLight = "#5F7891"
export const yellow = "#F5CA48"
export const mustardYellow = "#FDC913"
export const lightYellow = "#F3EC57"
export const red = "#DA3A4B"
export const green = "#4FAF8A"
export const greenBright = "#7BE4AF"
export const midGrey = "#6C6F72"
export const extraLightGrey = "#FCFCFC"
export const softGrey = "#9E9E9E"
export const lightBlue = "#54C2FF"
export const navy = "#0D4066"
export const easternBlue = "#1794A5"
export const turquoise = "#49E8AB"
export const heliotrope = "#8C52FF"
export const onahau = "#C7E3FF"

// Basic Colors
export const transparent = "transparent"
export const currentcolor = "currentcolor"
export const bgColor = extraLightGrey
export const mainColor = purple
export const alert = red
export const notify = yellow
export const success = green
export const textColor = indigo
export const lightTextColor = darken(0.07, indigoLight)
export const lightGrey = "#F3F5F5"
export const hrColor = rgba(textColor, 0.1)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
