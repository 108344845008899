exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-full-spectrum-bm-training-js": () => import("./../../../src/pages/full-spectrum-bm-training.js" /* webpackChunkName: "component---src-pages-full-spectrum-bm-training-js" */),
  "component---src-pages-lease-bm-training-js": () => import("./../../../src/pages/lease-bm-training.js" /* webpackChunkName: "component---src-pages-lease-bm-training-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/BlogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/CategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/PostTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-text-page-template-jsx": () => import("./../../../src/templates/TextPageTemplate.jsx" /* webpackChunkName: "component---src-templates-text-page-template-jsx" */)
}

