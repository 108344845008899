import MaterialIconsWoff from "../assets/fonts/icons/material-icons-regular.woff"
import MaterialIconsWoff2 from "../assets/fonts/icons/material-icons-regular.woff2"

import GilroyExtraBoldWoff from "../assets/fonts/gilroy/Gilroy-ExtraBold.woff"
import GilroyExtraBoldWoff2 from "../assets/fonts/gilroy/Gilroy-ExtraBold.woff2"

import GilroyRegularWoff from "../assets/fonts/gilroy/Gilroy-Regular.woff"
import GilroyRegularWoff2 from "../assets/fonts/gilroy/Gilroy-Regular.woff2"

import InterSemiboldWoff from "../assets/fonts/inter/Inter-SemiBold.woff"
import InterSemiboldWoff2 from "../assets/fonts/inter/Inter-SemiBold.woff2"

import InterMediumWoff from "../assets/fonts/inter/Inter-Medium.woff"
import InterMediumWoff2 from "../assets/fonts/inter/Inter-Medium.woff2"

import InterBoldWoff from "../assets/fonts/inter/Inter-Bold.woff"
import InterBoldWoff2 from "../assets/fonts/inter/Inter-Bold.woff2"

import InterExtraBoldWoff from "../assets/fonts/inter/Inter-ExtraBold.woff"
import InterExtraBoldWoff2 from "../assets/fonts/inter/Inter-ExtraBold.woff2"

export const fontFace = (
  fontName,
  woff,
  woff2,
  fontWeight = 400,
  fontStyle = "normal"
) => `
	@font-face {
		font-family: '${fontName}';
		src:  url('${woff}') format('woff'),
					url('${woff2}') format('woff2');
		font-weight: ${fontWeight};
		font-style: ${fontStyle};
		font-display: swap;
	}
`
export const MaterialIcons = "Material Icons"
export const MaterialIconsFont = fontFace(
  MaterialIcons,
  MaterialIconsWoff,
  MaterialIconsWoff2
)

export const Gilroy = "Gilroy"
export const GilroyRegularFont = fontFace(
  Gilroy,
  GilroyRegularWoff,
  GilroyRegularWoff2,
  "400"
)
export const GilroyExtraBoldFont = fontFace(
  Gilroy,
  GilroyExtraBoldWoff,
  GilroyExtraBoldWoff2,
  "700"
)

export const Inter = "Inter"
export const InterMediumFont = fontFace(
  Inter,
  InterMediumWoff,
  InterMediumWoff2,
  "normal"
)
export const InterSemiboldFont = fontFace(
  Inter,
  InterSemiboldWoff,
  InterSemiboldWoff2,
  "700"
)
export const InterBoldFont = fontFace(
  Inter,
  InterBoldWoff,
  InterBoldWoff2,
  "600"
)
export const InterExtraBoldFont = fontFace(
  Inter,
  InterExtraBoldWoff,
  InterExtraBoldWoff2,
  "800"
)
